.App {
  .bar {
    position: sticky;

    z-index: 2 !important;
    height: 80px;
    left: 0px;
    top: 0px;

    background: linear-gradient(to bottom, #ff4180, #ff5628);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    // margin-bottom: 50px;
    padding: 0 3rem;
    display: flex;
    justify-content: flex-end;
    gap: 32px;
    align-items: center;
  }
  // padding-bottom: 100px;
}

.logout-btn {
  background-color: transparent;
  border: none;
}

.head-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  // line-height: 27px;
  text-decoration: none;
  color: white;
}

@font-face {
  font-family: Montserrat;
  src: url("./Assets/Fonts/Montserrat-VariableFont_wght.ttf");
}

// @import "~bootstrap/scss/bootstrap";
