@media only screen and (max-width: 900px) {
  .login-page {
    .box-form .left .overlay h1 {
      font-size: 5vmax !important;
    }
    .box-form .left .overlay {
      height: 400px !important;
    }
  }
}

.login-page {
  margin: 100px 0;
  .box-form {
    margin: 0 auto;
    width: 80%;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex: 1 1 100%;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 0 0 20px 6px #090b6f85;
  }
  @media (max-width: 980px) {
    .box-form {
      flex-flow: wrap;
      text-align: center;
      align-content: center;
      align-items: center;
    }
  }
  .box-form div {
    height: auto;
  }
  .box-form .left {
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://i.pinimg.com/736x/5d/73/ea/5d73eaabb25e3805de1f8cdea7df4a42--tumblr-backgrounds-iphone-phone-wallpapers-iphone-wallaper-tumblr.jpg");
    overflow: hidden;
  }
  .box-form .left .overlay {
    padding: 30px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      rgba(30, 171, 216, 0.8),
      rgba(126, 34, 130, 0.8)
    );

    overflow: hidden;
    box-sizing: border-box;
  }
  .box-form .left .overlay h1 {
    font-size: 7vmax;
    line-height: 1;
    font-weight: 900;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: left;
  }
  .box-form .left .overlay p {
    margin-top: 100px;
    // font-weight: 900;
    text-align: left;
  }
  .box-form .left .overlay span a {
    background: #3b5998;
    color: #ffffff;
    margin-top: 10px;
    padding: 14px 50px;
    border-radius: 100px;
    display: inline-block;
    box-shadow: 0 3px 6px 1px #042d4657;
  }
  .box-form .left .overlay span a:last-child {
    background: #1dcaff;
    margin-left: 30px;
  }
  .box-form .right {
    padding: 40px;
    overflow: hidden;
  }
  @media (max-width: 980px) {
    .box-form .right {
      width: 100%;
    }
  }
  .box-form .right h5 {
    font-size: 6vmax;
    margin: auto;
    // line-height: 0;
  }
  .box-form .right p {
    font-size: 14px;
    color: #b0b3b9;
  }
  .box-form .right .inputs {
    overflow: hidden;
  }
  .box-form .right input {
    width: 100%;
    padding: 10px;
    margin-top: 25px;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: 2px solid #b0b3b9;
  }
  .box-form .right .remember-me--forget-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-form .right .remember-me--forget-password input {
    margin: 0;
    margin-right: 7px;
    width: auto;
  }
  .box-form .right .submit-btn {
    float: right;
    color: #fff;
    font-size: 16px;
    padding: 12px 35px;
    border-radius: 50px;
    display: inline-block;
    border: 0;
    outline: 0;
    box-shadow: 0px 4px 20px 0px #49c628a6;
    background-image: linear-gradient(135deg, #70f570 10%, #49c628 100%);
  }

  label {
    display: block;
    position: relative;
    margin-left: 30px;
  }

  label::before {
    content: " \f00c";
    position: absolute;
    font-family: FontAwesome;
    background: transparent;
    border: 3px solid #70f570;
    border-radius: 4px;
    color: transparent;
    left: -30px;
    transition: all 0.2s linear;
  }

  label:hover::before {
    font-family: FontAwesome;
    content: " \f00c";
    color: #fff;
    cursor: pointer;
    background: #70f570;
  }

  label:hover::before .text-checkbox {
    background: #70f570;
  }

  label span.text-checkbox {
    display: inline-block;
    height: auto;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  label input[type="checkbox"] {
    display: none;
  }

  .submit-btn {
    z-index: 100;
    cursor: pointer;
  }
}
